require("dotenv").config({
  path: `.env`
});

//const path = require("path");

module.exports = {
  siteMetadata: {
    title: "Creativity Rules 2.0",
    description: "Website of the Creativity Rules event",
    siteUrl: "https://creativityrules2020.netlify.com"
  },
  pathPrefix: "/",
  plugins: [
    {
      resolve: "@directus/gatsby-source-directus",
      options: {
        url: process.env.API_HOST,
        project: process.env.API_PROJECT,
        auth: {
          email: process.env.API_USER,
          password: process.env.API_PASSWORD
        },
        targetStatuses: ["published", "__NONE__"],
        downloadFiles: true
      }
    },
    {
      resolve: `gatsby-plugin-gdpr-cookies`,
      options: {
        googleAnalytics: {
          trackingId: "",
          // Setting this parameter is optional
          anonymize: true
        },
        // Defines the environments where the tracking should be available  - default is ["production"]
        environments: ["production", "development"]
      }
    },
    {
      resolve: "gatsby-source-apiserver",
      options: {
        typePrefix: "internal__",
        url: `https://portfolio.multimediatechnology.at/editions/cr-2020.json`,
        name: `projects2019`
      }
    },
    {
      resolve: "gatsby-transformer-remark",
      options: {
        plugins: [
          {
            resolve: "gatsby-remark-images",
            options: {
              maxWidth: 750,
              linkImagesToOriginal: false,
              wrapperStyle: "margin-bottom: 1.0725rem;"
            }
          },
          {
            resolve: "gatsby-remark-responsive-iframe",
            options: {
              wrapperStyle: "margin-bottom: 1.0725rem"
            }
          },
          "gatsby-remark-prismjs",
          "gatsby-remark-copy-linked-files",
          "gatsby-remark-smartypants"
        ]
      }
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: "Gatstrap",
        short_name: "Gatstrap",
        description: "Gatsby starter for bootstrap a blog",
        homepage_url: "https://gatstrap.netlify.com",
        start_url: "/",
        background_color: "#fff",
        theme_color: "#673ab7",
        display: "standalone",
        icons: [
          {
            src: "/static/favicons/android-chrome-192x192.png",
            sizes: "192x192",
            type: "image/png"
          },
          {
            src: "/static/favicons/android-chrome-512x512.png",
            sizes: "512x512",
            type: "image/png"
          }
        ]
      }
    },
    {
      resolve: "gatsby-plugin-google-analytics",
      options: {
        trackingId: ""
      }
    },
    {
      resolve: "gatsby-plugin-netlify",
      options: {
        mergeSecurityHeaders: true,
        mergeLinkHeaders: true,
        mergeCachingHeaders: true
      }
    },
    "gatsby-plugin-catch-links",
    "gatsby-plugin-offline",
    "gatsby-plugin-react-helmet",
    "gatsby-plugin-sass",
    "gatsby-plugin-sharp",
    "gatsby-plugin-sitemap",
    "gatsby-plugin-twitter",
    "gatsby-transformer-sharp"
  ]
};
