import React from "react";
import { Helmet } from "react-helmet";
import get from "lodash/get";
import favicon16 from "../../../static/favicons/favicon-16x16.png";
import favicon32 from "../../../static/favicons/favicon-32x32.png";
import appleTouchIcon from "../../../static/favicons/apple-touch-icon.png";
//import manifest from '../../../static/favicons/site.webmanifest'
import shortcutIcon from "../../../static/favicons/favicon.ico";
import maskIcon from "../../../static/favicons/safari-pinned-tab.svg";

const Meta = ({ site, title }) => {
  const siteTitle = get(site, "title");
  title = title ? `${title} | ${siteTitle}` : siteTitle;
  return (
    <Helmet
      title={title}
      meta={[
        {
          name: "description",
          content: "Website for the Creativity Rules Festival 2020"
        },
        {
          name: "msapplication-TileColor",
          content: "#ff7d00"
        },
        {
          name: "msapplication-config",
          content: "/static/favicons/browserconfig.xml"
        },
        {
          name: "theme-color",
          content: "#ff7d00"
        },
        {
          name: "keywords",
          content: "creativity, festival, 2020, new, realities"
        }
      ]}
      link={[
        {
          rel: "apple-touch-icon",
          type: "image/png",
          sizes: "180x180",
          href: `${appleTouchIcon}`
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "16x16",
          href: `${favicon16}`
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "32x32",
          href: `${favicon32}`
        },
        { rel: "mask-icon", color: "#ff7d00", href: `${maskIcon}` },
        { rel: "shortcut icon", href: `${shortcutIcon}` }
        //{ rel: "manifest", href: `${manifest}` }
      ]}
    />
  );
};
export default Meta;
