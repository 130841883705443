import React from "react";
import emergence from "emergence.js";

import "modern-normalize/modern-normalize.css";
import "prismjs/themes/prism.css";
import "../../scss/general.scss";
import "animate.css/animate.css";
import "font-awesome/css/font-awesome.css";

class Layout extends React.Component {
  componentDidMount() {
    emergence.init();
  }

  componentDidUpdate() {
    emergence.init();
  }

  render() {
    const { children } = this.props;
    return <div>{children}</div>;
  }
}

export default Layout;
